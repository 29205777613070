import { $http, $httpErrorHandler } from "@/src/plugins/vue_axios"

import type { LoginTypes } from "./login-page/login-page.types"

const loginUrl = "/login"
const recoverUrl = "/recover"
const mfaVerifyUrl = "/auth/mfa/verify"

type PostLogin =
  | {
      login_type: Omit<LoginTypes, "">
    }
  | { redirect: string }

export const postLogin = async ({
  urlParams,
  post
}: {
  urlParams?: string
  post: { email: string; password?: string }
}): Promise<PostLogin> => {
  const url = urlParams ? `${loginUrl}${urlParams}` : loginUrl
  const { data } = await $http.post(url, post)
  return data
}

export const postRecover = async (email: string) => {
  try {
    await $http.post(recoverUrl, {
      email: email
    })
  } catch (error) {
    $httpErrorHandler(error)
  }
}

export const postResetPassword = async ({ newPassword, resetToken }) => {
  try {
    const { data } = await $http.post("/profile/reset-password", {
      new_password: newPassword,
      reset_token: resetToken
    })

    return data
  } catch (error) {
    $httpErrorHandler(error)
    throw error
  }
}

export const postMfaVerify = async ({
  urlParams,
  mfaCode
}: {
  urlParams?: string
  mfaCode: string
}) => {
  try {
    const url = urlParams ? `${mfaVerifyUrl}${urlParams}` : mfaVerifyUrl
    const { data } = await $http.post(url, {
      mfa_code: mfaCode
    })
    return data
  } catch (error) {
    $httpErrorHandler(error)
    throw error
  }
}
